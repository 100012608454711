import { parseStringPromise } from 'xml2js'
import { firstCharLowerCase, parseBooleans, parseNumbers } from 'xml2js/lib/processors'
import { Topology as TopologyInterface } from './interfaces'
import { extractType, extractSyncManagers, extractPDOs } from './extractors'
import { parseIndex } from './processors'
import { changePdosNameToGroup } from '../utils'

class Topology {
  static async import(xmlData: string) {
    const result = await parseStringPromise(xmlData, {
      tagNameProcessors: [firstCharLowerCase],
      attrNameProcessors: [firstCharLowerCase],
      valueProcessors: [parseNumbers, parseBooleans, parseIndex],
      attrValueProcessors: [parseNumbers, parseBooleans],
      trim: true,
      explicitArray: false,
    })

    return this.formatTopology(result)
  }

  static formatTopology(result: any): TopologyInterface[] {
    const ecatList = result?.etherCATInfoList?.etherCATInfo ?? [result?.etherCATInfo]

    return ecatList
      .filter((device: any) => device !== null && device !== undefined)
      .flatMap((node: any, id: number) => {
        const device = node.descriptions.devices.device
        const vendor = node.vendor

        const type = extractType(device.type)
        const syncManagers = extractSyncManagers(device.sm)
        const rxPdos = changePdosNameToGroup(extractPDOs(device.rxPdo, 'input'))
        const txPdos = changePdosNameToGroup(extractPDOs(device.txPdo, 'output'))

        return {
          id,
          vendorId: vendor.id,
          name: device.name || '',
          type,
          syncManagers,
          rxPdos,
          txPdos,
        }
      })
  }
}

export default Topology
