import { removeNumbersFromString, removeParenthesesFromString } from '../../utils'

export enum TypeSignature {
  NOT_SET,
  BIT,
  BOOL,
  INT,
  UINT,
  FLOAT,
  DOUBLE,
  STRING,
}

export function listKeys() {
  return Object.keys(TypeSignature)
    .filter((key) => !(parseInt(key) >= 0))
    .filter((key) => typeof TypeSignature[key as any] !== 'function')
}

export function findByString(type: string): TypeSignature {
  let typeString = removeParenthesesFromString(removeNumbersFromString(type.toUpperCase()))

  if (['SINT', 'DINT', 'LINT'].includes(typeString)) {
    typeString = 'INT'
  }

  if (['BYTE', 'DWORD', 'LWORD', 'UDINT', 'ULINT', 'USINT', 'WORD'].includes(typeString)) {
    typeString = 'UINT'
  }

  if (['STRING'].includes(typeString)) {
    typeString = 'STRING'
  }

  if (['REAL'].includes(typeString)) {
    typeString = 'FLOAT'
  }

  if (['LREAL'].includes(typeString)) {
    typeString = 'DOUBLE'
  }

  if (!listKeys().includes(typeString)) {
    typeString = 'NOT_SET'

    console.warn(`Unknown signature: ${type}`)
  }

  return TypeSignature[typeString as keyof typeof TypeSignature]
}
